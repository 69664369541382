/* styles.css */
body {
  margin: 0;
  padding: 0;
}

span.amplify-text.amplify-radio__label {
  font-size: 0.8rem
}

label.amplify-label {
  font-size: 0.8rem
}

.amplify-expander {
  --amplify-components-expander-border-radius: none;
  --amplify-components-expander-item-border-end-end-radius: none;
  --amplify-components-expander-item-border-start-end-radius: none;
  --amplify-components-expander-item-border-start-start-radius: none;
  --amplify-components-expander-item-border-end-start-radius: none;
  --amplify-components-expander-item-focus-box-shadow: none ;
}

h3.amplify-expander__header {
  font-size: 0.8rem;
}

button .amplify-text {
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
}

span.amplify-switch-track {
  min-width:25px;
}

mgt-login {
  --font-size: 16px;
  --font-weight: 400;
  --weith: '100%';
  --height: '100%';
  --margin: 0;
  --padding: 12px 20px;
  --button-background-color: transparent;
  --button-background-color--hover: transparent;
  --popup-command-font-size: 12px;
}

.portal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.portal-overlay .confirm-dialog {
  z-index: 1000000000000111;
  width: 400px;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
}